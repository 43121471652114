import '/style.css'
import Setup from '/Experience/setup.js'
import '/Experience/animation.js'
import gsap from "gsap";

import ScrollTrigger from 'gsap/dist/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

export const exp = new Setup(document.querySelector('canvas.webgl'));
export default exp;

// Text animations on landing page
function animateHeader() {
    let tl = gsap.timeline();

    tl.from(exp.mainBlob.mesh.scale, {
        x: 0.5,
        y: 0.5,
        z: 0.5,
        duration: 2,
        ease: 'power4.inOut'
    })

    tl.from('.slide-left', {
        opacity: 0,
        xPercent: +100,
        duration: 1.5,
        stagger: 0.2,
        delay: -1.2,
        ease: 'power4.inOut'
    });

    tl.from('.slide-right', {
        opacity: 0,
        xPercent: -100,
        duration: 1.5,
        stagger: 0.2,
        delay: -1.2,
        ease: 'power4.inOut'
    });

    tl.from('.scroll-arrow', {
        opacity: 0,
        duration: 2,
        delay: -0.8,
        ease: 'power4.inOut'
    });
}

function frequencyButtons() {
    const getFrequencyBtns = document.querySelectorAll('.frequency-btn');
    const frequencyBtns = gsap.utils.toArray(getFrequencyBtns);
    let frequencyUniform = exp.mainBlob.material.uniforms.uFrequency;
    const frequencyDefault = 1.2;

    frequencyBtns.forEach(btn => {
        let clicked = false;
        btn.addEventListener('click', (e) => {
            if(clicked === false){
                console.log('Clicked Button and set Frequency');
                clicked = true;
                console.log(e);
                gsap.to(frequencyUniform, {
                    value: e.target.dataset.uvalue,
                    duration: 1.2,
                    ease: 'power3.inOut'
                });
            } else if (clicked === true) {
                console.log('Clicked Button and removed Frequency');
                clicked = false;
                console.log(clicked);
                gsap.to(frequencyUniform, {
                    value: frequencyDefault,
                    duration: 1.2,
                    ease: 'power3.inOut'
                });
            }
        });
    });
}

function amplitudeButtons() {
    const getAmplitudeBtns = document.querySelectorAll('.amplitude-btn');
    const amplitudeBtns = gsap.utils.toArray(getAmplitudeBtns);
    let amplitudeUniform = exp.mainBlob.material.uniforms.uAmplitude;
    let amplitudeDefault = 1.8;

    amplitudeBtns.forEach(btn => {
        let clicked = false;
        btn.addEventListener('click', (e) => {
            if(clicked === false){
                console.log('Clicked Button and set amplitude');
                clicked = true;

                gsap.to(amplitudeUniform, {
                    value: e.target.dataset.uvalue,
                    duration: 1.2,
                    ease: 'power3.inOut'
                });
            } else if (clicked === true) {
                console.log('Clicked Button and removed amplitude');
                clicked = false;

                gsap.to(amplitudeUniform, {
                    value: amplitudeDefault,
                    duration: 1.2,
                    ease: 'power3.inOut'
                });
            }
            gsap.to(exp.mainBlob.mesh.rotation, {
                x: '+=10',
                y: '+=10',
                duration: 1.8,
                ease: 'power3.inOut'
            });
        });
    });
}

function colourButton() {
    const colourBtn = document.getElementById('colour-btn')
    let clicked = false;
    colourBtn.addEventListener('click', (e) => {
        if (clicked === false) {
            clicked = true;
            gsap.to(exp.mainBlob.material.uniforms.uFirstColour.value, {
                x: 0.82,
                y: 0.337,
                z: 0.337,
                duration: 1.2
            });
            gsap.to(exp.mainBlob.material.uniforms.uSecondColour.value, {
                x: 0.145,
                y: 0.145,
                z: 0.145,
                duration: 1.2
            });
            gsap.to(exp.mainBlob.mesh.rotation, {
                x: '+=10',
                y: '+=10',
                duration: 1.8,
                ease: 'power3.inOut'
            });
            // exp.mainBlob.material
        } else {   
            gsap.to(exp.mainBlob.material.uniforms.uFirstColour.value, {
                x: 0.145,
                y: 0.145,
                z: 0.145,
                duration: 1.2
            });
            gsap.to(exp.mainBlob.material.uniforms.uSecondColour.value, {
                x: 0.82,
                y: 0.337,
                z: 0.337,
                duration: 1.2
            });
            gsap.to(exp.mainBlob.mesh.rotation, {
                x: '-=10',
                y: '-=10',
                duration: 1.8,
                ease: 'power3.inOut'
            });
            clicked = false;
        }
    })
}

function intensityButton(){
    const intensityBtn = document.getElementById('intensity-btn');
    let intensityUniform = exp.mainBlob.material.uniforms.uIntensity;
    let clicked = false;

    intensityBtn.addEventListener('click', (e) =>{
        if (clicked === false) {
            clicked = true;
            gsap.to(intensityUniform, {
                value: e.target.dataset.uvalue,
                duration: 1.2
            });
            gsap.to(exp.mainBlob.mesh.rotation, {
                x: '+=10',
                y: '+=10',
                duration: 1.8,
                ease: 'power3.inOut'
            })
        } else {
            gsap.to(intensityUniform, {
                value: 1.0,
                duration: 1.2
            });
            gsap.to(exp.mainBlob.mesh.rotation, {
                x: '-=10',
                y: '-=10',
                duration: 1.8,
                ease: 'power3.inOut'
            });
            clicked = false;
        }
    });
}

function shaderButtons(){
    const getShaderButtons = document.querySelectorAll('.shader-btn');
    const shaderButtons = gsap.utils.toArray(getShaderButtons);

    shaderButtons.forEach(btn => {
        btn.addEventListener('mouseenter', () => {
            gsap.to(btn, {
                background: '#CE4646',
                color: '#EAEAEA',
                duration: 0.1,
                ease: 'power4.inOut'
            })
        })
        btn.addEventListener('mouseleave', () => {
            gsap.to(btn, {
                background: 'transparent',
                color: '#EAEAEA',
                duration: 0.1,
                ease: 'power4.inOut'
            })
        })
        btn.addEventListener('click', () => {
            gsap.to(btn, {
                scale: 1.1,
                color: '#CE4646',
                duration: 0.1,
                yoyo: true,
                repeat: 1
            })
        })
    });
}

window.addEventListener('DOMContentLoaded', () => {
    animateHeader();
    shaderButtons();
    frequencyButtons();
    amplitudeButtons();
    colourButton();
    intensityButton();
})
