import * as THREE from 'three';
import vertex from '../Shaders/vertex.glsl';
import fragment from '../Shaders/fragment.glsl';

export default class Blob{
    constructor(){
        this.geometry = new THREE.IcosahedronGeometry(1, 150);
        // this.geometry = new THREE.SphereGeometry(1,35, 35);
        // this.material = new THREE.MeshBasicMaterial({
        //     side: THREE.DoubleSide,
        //     wireframe: true
        // });

        this.material = new THREE.ShaderMaterial({
            uniforms:{
              uTime: { value: 0 },
              uFrequency: { value: 1.2 },
              uAmplitude: { value: 1.1 },
              uIntensity: { value: 1.1 },
              uFirstColour: { value: new THREE.Vector3(0.145, 0.145, 0.145)},
              uSecondColour: { value: new THREE.Vector3(0.82,0.337,0.337)}
            },
            vertexShader: vertex,
            fragmentShader: fragment,
            // wireframe: true
          }); 
          
          this.mesh = new THREE.Mesh(this.geometry, this.material);
    }
}