import * as THREE from 'three';
import { OrbitControls } from "three/addons/controls/OrbitControls.js";
import Blob from './blob.js';
import { gsap } from "gsap";



export default class Setup {
  constructor(_canvas) {

    // Options
    this.canvas = _canvas;
    this.mainBlob = new Blob();


    // Setup
    this.scene = new THREE.Scene();
    this.width = window.innerWidth;
    this.height = window.innerHeight;
    this.pixelRatio = Math.min(window.devicePixelRatio, 2);
    this.start = Date.now()
    this.current = this.start
    this.elapsed = 0
    this.delta = 16

    this.resize();
    this.addCamera();
    this.addToScene();
    this.setRenderer();
    this.render();
  }

  resize() {
    window.addEventListener('resize', () => {
      this.width = window.innerWidth;
      this.height = window.innerHeight;
      this.pixelRatio = Math.min(window.devicePixelRatio, 2);
      this.renderer.setSize(this.width, this.height);
      this.renderer.setPixelRatio(Math.min(this.pixelRatio, 2));
      this.camera.aspect = this.width / this.height;
      this.camera.updateProjectionMatrix()
    })
  }

  addCamera() {
    this.camera = new THREE.PerspectiveCamera(35, this.width / this.height, 0.1, 100);
    this.camera.position.set(0, 0, 6);
    this.scene.add(this.camera);

    // this.controls = new OrbitControls(this.camera, this.canvas);
    // this.controls.enableDamping = true
  }

  // mouseMovement() {

  //   window.addEventListener('mousemove', (e) => {
  //     this.mouse.x = (e.clientX / this.width) * 2 - 1;
  //     this.mouse.y = - (e.clientY / this.height) * 2 + 1;
  //     this.raycaster.setFromCamera(this.mouse, this.camera);

  //     const intersects = this.raycaster.intersectObjects(this.scene.children);

  //     if (intersects.length > 0) {
  //       // console.log(intersects[0]);
  //       setTimeout(() => {
  //         let obj = intersects[0].object;
  //         obj.material.uniforms.hover.value = intersects[0].face.normal
  //       }, 50);


  //       gsap.to(this.mainBlob.material.uniforms.uDisplacementFactor, {
  //         value: 5.0,
  //         duration: 0.6,
  //       })
  //       gsap.to(this.mainBlob.material.uniforms.uShapeFactor, {
  //         value: 3.5,
  //         duration: 0.6,
  //       })
  //     } else {
  //       gsap.to(this.mainBlob.material.uniforms.uDisplacementFactor, {
  //         value: 0.8,
  //         duration: 0.6,
  //       })
  //     }
  //   });
  // }

  addToScene() {
    this.scene.add(this.mainBlob.mesh);
  }

  setRenderer() {
    this.renderer = new THREE.WebGLRenderer({
      canvas: this.canvas,
      antialias: true,
      alpha: true
    })
    this.renderer.setSize(this.width, this.height);
    this.renderer.setPixelRatio(Math.min(this.pixelRatio, 2));
  }

  render() {
    const currentTime = Date.now();
    this.delta = currentTime - this.current;
    this.current = currentTime;
    this.elapsed = this.current - this.start;

    this.mainBlob.material.uniforms.uTime.value = this.elapsed;
    // this.controls.update(this.elapsed)

    this.renderer.render(this.scene, this.camera);

    window.requestAnimationFrame(() => {
      this.render();
    })
  }
}